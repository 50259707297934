"use client";

import { ConfigProvider, theme, ThemeConfig } from "antd";

import React, { useContext, useEffect, useState } from "react";
import defaultTheme from "@/config/defaultTheme";
import { ThemeProvider } from "styled-components";
import useCurrentCompany from "@/services/company/useCurrentCompany";
import { useSearchParams } from "next/navigation";
import { useLocalStorage } from "usehooks-ts";

import { LOCAL_STORAGE_KEYS } from "@/utils/app/constants";

interface GlobalConfigProviderProps {
  children: React.ReactNode;
}

interface GlobalConfigContextState {
  theme: ThemeConfig;
  setTheme: (input: Partial<ThemeConfig>) => void;
  isDisabledLayout: boolean;
  checkingPermission: boolean;
  setCheckingPermission: (x: boolean) => void;
}

const GlobalConfigContext = React.createContext<
  GlobalConfigContextState | undefined
>(undefined);

const InjectThemeIntoStyledComponents = ({
  children,
}: GlobalConfigProviderProps) => {
  const antdTheme = theme.useToken();
  return <ThemeProvider theme={antdTheme}>{children}</ThemeProvider>;
};

export const GlobalConfigProvider = ({
  children,
}: GlobalConfigProviderProps) => {
  const searchParams = useSearchParams();
  const layout = searchParams.get("layout");
  const [isDisabledLayout] = useState(layout === "false");
  const [checkingPermission, setCheckingPermission] = useLocalStorage(
    LOCAL_STORAGE_KEYS.CHECKING_PERMISSION,
    true,
    {
      initializeWithValue: false,
    }
  );
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);
  const _setTheme = (input: Partial<ThemeConfig>) => {
    setCurrentTheme((prev) => ({ ...prev, ...input }));
  };

  return (
    <GlobalConfigContext.Provider
      value={{
        theme: currentTheme,
        setTheme: _setTheme,
        isDisabledLayout,
        checkingPermission,
        setCheckingPermission,
      }}>
      <ConfigProvider theme={currentTheme}>
        <InjectThemeIntoStyledComponents>
          {children}
        </InjectThemeIntoStyledComponents>
      </ConfigProvider>
    </GlobalConfigContext.Provider>
  );
};

export function useGlobalConfig(): GlobalConfigContextState {
  const context = useContext<any>(GlobalConfigContext);

  if (!context) {
    throw Error("useGlobalConfig must be used within a GlobalConfigProvider");
  }
  return context;
}
