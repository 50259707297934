import SelectWithIcon from "@/components/shared/SelectWithIcon";
import { Filter, PropertyType } from "@/services/types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DefaultOptionType } from "antd/es/select";
import { Form } from "antd";
import useGetCompanyProfile from "@/services/profile/useGetCompanyProfile";
import { useDebouncedSearch } from "../../../contact/hooks/useDebouncedSearch";
import { Rule } from "antd/es/form";

type SingleUserFilterProps = {
  prefixIcon?: React.ReactNode;
  placeholder?: string;
  property: PropertyType;
  onFilterChange?: (filter: Filter | string) => void;
  defaultValue?: string | null;
  operator?: string;
  type?: string;
  mode?: "multiple";
  maxTagCount?: "responsive" | number;
  style?: React.CSSProperties;
  formItemName?: (string | number)[] | string;
  label?: string;
  rules?: Rule[];
  hasMargin?: boolean;
};

export const UserFilter = (props: SingleUserFilterProps) => {
  const {
    property,
    onFilterChange,
    defaultValue,
    operator = "EQUAL",
    type = "singleuserproperty",
    formItemName,
    label,
    rules,
    hasMargin = false,
    ...restProps
  } = props;
  const { search, onSearch } = useDebouncedSearch();
  const { data: optionValue } = useGetCompanyProfile(search);
  const [filterValue, setFilterValue] = useState<string | string[] | null>(
    null
  );

  const handleFilterChange = (value: string | string[]) => {
    setFilterValue(value);
    const listOptionValue = optionValue?.data ?? [];
    const option = !props.mode
      ? listOptionValue.find((item) => item.id === value)
      : value.length > 0;
    if (option) {
      const filter = {
        property: property.id,
        type: type,
        slug: property.slug,
        name: property.name,
        operator: operator,
        value:
          restProps.mode === "multiple" || type === "multiuserproperty"
            ? [...value]
            : value,
      };
      onFilterChange && onFilterChange(filter);
    } else {
      onFilterChange && onFilterChange(property.slug);
    }
  };

  const filterOptions: DefaultOptionType[] = useMemo(() => {
    const listOptionValue = optionValue?.data ?? [];
    return listOptionValue.map(({ id, email, full_name }) => ({
      value: id,
      label: full_name ? full_name : email,
    }));
  }, [optionValue]);

  useEffect(() => {
    if (defaultValue) {
      setFilterValue(defaultValue);
    } else {
      setFilterValue(null);
    }
  }, [defaultValue]);
  return (
    <>
      <Form.Item
        name={formItemName}
        style={!hasMargin ? { margin: 0 } : {}}
        label={label}
        rules={rules}>
        <SelectWithIcon
          {...restProps}
          options={filterOptions}
          onChange={handleFilterChange}
          allowClear
          value={filterValue}
          showSearch
          filterOption={false}
          placement="bottomLeft"
          popupMatchSelectWidth={false}
          onSearch={onSearch}
          onFocus={() => onSearch(undefined)}
        />
      </Form.Item>
    </>
  );
};

export default UserFilter;
